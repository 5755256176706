<template>
  <div style="width: 100%;">
    <v-container fluid>
      <v-row>
        <v-col class="d-flex flex-row">
          <h1>Purchasing</h1>
          <v-progress-circular
              indeterminate
              color="green"
              v-if="loader"
              style="margin-left: 10px;"
          ></v-progress-circular>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex flex-row align-center">
          <h2>{{this.plural}}</h2>
          <v-btn
            class="mx-2"
            fab
            small
            dark
            color="info"
            @click="createA"
          >
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-data-table
                :headers="headers"
                :items="purchaseOrders"
                :items-per-page="-1"
                class="elevation-1"
                @click:row="rowClick"
            >
              <template v-slot:item.status="{ item }">
                <v-chip :color="utils.getPOStatusColor(item.status)">{{utils.parsePOStatus(item.status)}}</v-chip>
              </template>
              <template v-slot:item.createdBy="{ item }">
                <span>{{lookupUsername(item.createdBy)}}</span>
              </template>
              <template v-slot:item.sealedBy="{ item }">
                <span>{{lookupUsername(item.sealedBy)}}</span>
              </template>
              <template v-slot:item.createdAt="{ item }">
                <span>{{utils.formatDate(item.createdAt, 'short')}}</span>
              </template>
              <template v-slot:item.sealedAt="{ item }">
                <span>{{utils.formatDate(item.sealedAt, 'short')}}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="createDialog.isOpen" max-width="490">
      <v-card>
        <v-card-title class="text-h5">
          Create New Purchase Order
        </v-card-title>
        <v-card-actions>
          <v-btn color="error" text @click="cancelCreate()">
            Cancel
          </v-btn>
          <v-btn color="success" text @click="createB">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import axios from 'axios';
  import { mapGetters, mapMutations } from "vuex"
  import utils from "../../plugins/helpers"
  export default {
    data () {
      return {
        utils: utils,
        deleteDialog: false,
        deleteConfirmed: true,
        loader: true,
        snack: false,
        snackColor: '',
        snackText: '',
        tableTitle: '',
        max25chars: v => v.length <= 25 || 'Input too long!',
        headers: [
          { text: '#', align: 'start', sortable: true, value: 'id' },
          { text: 'Status', align: 'start', sortable: true, value: 'status' },
          { text: 'Supplier', align: 'start', sortable: true, value: 'supplierInfo.name' },
          { text: '# of Items', align: 'start', sortable: true, value: 'numItems' },
          { text: 'Date Created', align: 'start', sortable: true, value: 'createdAt' },
          { text: 'Created By', align: 'start', sortable: true, value: 'createdBy' },
          { text: 'Date Sealed', align: 'start', sortable: true, value: 'sealedAt' },
          { text: 'Sealed By', align: 'start', sortable: true, value: 'sealedBy' },
        ],
        editMode: false,
        purchaseOrders: [],
        singular: "Purchase Order",
        singularLower: "puchaseorder",
        plural: "Purchase Orders",
        pluralLower: "purchaseorders",
        createDialog: {
          isOpen: false,
          confirmed: false
        }
      }
    },
    async mounted(){
        try {
          let res = await axios.get(`${this.getEndpoint}/api/purchaseorders`);
          if(res.data.error) throw res.data.error
          this.purchaseOrders = res.data.data
        } catch (error) {
          console.error(error);
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
            this.loader = false;
        }
    },
    computed: {
      ...mapGetters(['getId', 'getEndpoint', 'lookupUsername'])
    },
    methods: {
      ...mapMutations(['deleteWorkingId']),
      rowClick(row){
        if(row.status === 1) 
          this.$router.push({ path: `/${this.pluralLower}/view/${row.id}`})
        else
          this.$router.push({ path: `/${this.pluralLower}/create/${row.id}`})
      },
      createA(){
        this.createDialog.isOpen = true
      },
      async createB(){
        try {
          let res = await axios.post(`${this.getEndpoint}/api/${this.pluralLower}`)
          if(res.data.error) throw res.data.error  
          this.createDialog.isOpen = false
          await this.$router.push({ path: `/${this.pluralLower}/create/${res.data.data.id}`})
        } catch (error) {
          console.log(error)
          this.snack(error.msg || error.msg?.message || error, "error");
         this.createDialog.isOpen = false
        }
      },
      cancelCreate(){
        this.createDialog.isOpen = false
      },
      createRR(){
        this.$router.push({ path: `/receivingreports/create`})
      },
    }
  }
</script>
